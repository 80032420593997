<template>
    <a-table :columns="columns" :data-source="compareData"  style="margin-top: 20px;" :scroll="{ y: 400 }" :loading="loading">
        <span slot="kitchenWastePercent" slot-scope="text, record">{{record.kitchenWastePercent}}%</span>
        <span slot="otherPercent" slot-scope="text, record">{{record.otherPercent}}%</span>
    </a-table>
</template>

<script>
import {mapState} from "vuex";
import { queryCenterCompareResultApi } from '@/api/compare'
export default {
    data() {
        return{
            compareData: [],
            columns: [
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName'
                },
                {
                    title: '厨余分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent',
                    sorter: (a, b) => a.kitchenWastePercent - b.kitchenWastePercent,
                    scopedSlots: { customRender: 'kitchenWastePercent' },
                },
                {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent',
                    sorter: (a, b) => a.otherPercent - b.otherPercent,
                    scopedSlots: { customRender: 'otherPercent' },
                },
                {
                    title: '采集点数量',
                    dataIndex: 'collectPointNumbers',
                    key: 'collectPointNumbers',
                    sorter: (a, b) => a.collectPointNumbers - b.collectPointNumbers,
                },
                {
                    title: '设备数量',
                    dataIndex: 'deviceNumbers',
                    key: 'deviceNumbers',
                    sorter: (a, b) => a.deviceNumbers - b.deviceNumbers,
                },
                {
                    title: '厨余户均(kg)',
                    dataIndex: 'kitchenWasteHousehold',
                    key: 'kitchenWasteHousehold',
                    sorter: (a, b) => a.kitchenWasteHousehold - b.kitchenWasteHousehold,
                },
                {
                    title: '其他垃圾户均(kg)',
                    dataIndex: 'otherHousehold',
                    key: 'otherHousehold',
                    sorter: (a, b) => a.otherHousehold - b.otherHousehold,
                }
            ],
            params: {
                page: 1,
                limit: 5,
                startTime: '',
                endTime: ''
            },
            pagination: {
                pageSize: 5,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['5','10', '20', '30'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
            loading: false
        }
    },
    methods: {
        queryCenterCompareResult(params) {
            this.loading = true
            queryCenterCompareResultApi({...params}, this.$route.query.moduleCode).then(res => {
                this.compareData = res.result
                this.loading = false
            })
        },
        changePage(current, size) {
            this.params.page = current
            this.params.limit = size
            this.pagination.pageSize = size
            this.pagination.current = current
            this.params.streetCode = this.regionCode
            this.queryCenterCompareResult(this.params)
        },
        changeCeter(codes, time){
            this.params.streetCode = this.regionCode
            this.params.centerCodes = codes
            this.params.startTime = time[0]
            this.params.endTime = time[1]
            this.queryCenterCompareResult(this.params)
        }
    },
    computed: mapState({
        regionCode: state => state.user.module.extendsCode
    })
}
</script>