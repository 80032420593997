<template>
    <a-row type="flex" direction="horizontal">
      <a-col :span="18">
            <a-form>
                <a-form-item label="查询时间">
                    <a-range-picker v-model="query.time" @change="changeTime"/>
                </a-form-item>
            </a-form>
            <span :style="{ marginRight: 8 }">已选小区:</span>
            <a-tag closable @close="preventDefault($event, item)" v-for="item in checkedCenter" :key="item.nodeCode">
               {{item.nodeName}}
            </a-tag>
            <compare-table ref="table"></compare-table>
            <!-- <compare-rank ref="rank"></compare-rank> -->
      </a-col>
      <a-col :span="6">
          <tree ref="tree" @changeCheckedCenterList="changeCheckedCenterList"></tree>
      </a-col>
    </a-row>
</template>

<script>
import compareRank from './component/compareRank'
import moment from 'moment'
import tree from './component/tree'
import CompareTable from './component/compareTable.vue'
import _ from 'lodash'
export default {
    components: { tree , CompareTable, compareRank},
    data() {
        return {
            query: {
                time: [moment(), moment()]
            },
            checkedCenter: [],
            centerCodes: []
        }
    },
    mounted() {
        this.$refs.tree.queryCenterTree()
    },
    methods: {
        changeTime(e) {
            this.$refs.table.changeCeter(this.centerCodes, e)
            // this.$refs.rank.changeCeter(this.centerCodes, e)
        },
        preventDefault(e, item) {
            e.preventDefault();
            this.$refs.tree.changeCheckedKeys(item.nodeCode)
        },
        changeCheckedCenterList(data) {
            this.checkedCenter = []
            this.centerCodes = []
            data.forEach(item => {
                this.checkedCenter.push(item.data.props)
                this.centerCodes.push(item.data.key)
            })
            this.$refs.table.changeCeter(this.centerCodes, this.query.time)
            // this.$refs.rank.changeCeter(this.centerCodes, this.query.time)
        }
    }
    
}
</script>

