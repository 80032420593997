<template>
    <a-card :bordered="false" style="margin-top: 20px;">
        <template slot="title">
        <a-row align="middle" justify="space-between" type="flex">
            <a-col :span="4">
            <span style="font-weight: bolder; font-size: 20px;">厨余垃圾分出率排行榜</span>
            </a-col>
            <a-col :span="12">
            <a-form layout="inline">
                <a-form-item label="">
                </a-form-item>
            </a-form>
            </a-col>
        </a-row>
        </template>
        <a-spin :spinning="loading">
            <a-table :columns="columns" :data-source="compareData"  style="margin-top: 20px;" :scroll="{ y: 400 }" :loading="loading">
                <span slot="index" slot-scope="text, record, index">{{index}}</span>
            </a-table>
        </a-spin>
  </a-card>
</template>

<script>
import {mapState} from "vuex";
import { queryCenterCompareResultApi } from '@/api/compare'
export default {
    data() {
        return{
            loading: false,
            compareData: [],
            columns: [
                {
                    title: '排行',
                    dataIndex: 'index',
                    key: 'index',
                    scopedSlots: { customRender: 'index' },
                },
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName'
                },
                {
                    title: '厨余分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent'
                },
                {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent'
                },
                {
                    title: '采集点数量',
                    dataIndex: 'collectPointNumbers',
                    key: 'collectPointNumbers'
                },
                {
                    title: '设备数量',
                    dataIndex: 'deviceNumbers',
                    key: 'deviceNumbers'
                },
                {
                    title: '厨余户均(kg)',
                    dataIndex: 'kitchenWasteHousehold',
                    key: 'kitchenWasteHousehold'
                },
                {
                    title: '其他垃圾户均(kg)',
                    dataIndex: 'otherHousehold',
                    key: 'otherHousehold'
                }
            ],
            params: {
                page: 1,
                limit: 5,
                startTime: '',
                endTime: ''
            },
        }
    },
        methods: {
        queryCenterCompareResult(params) {
            this.loading = true
            queryCenterCompareResultApi({...params}, this.$route.query.moduleCode).then(res => {
                this.compareData = res.result
                this.loading = false
            })
        },
        changePage(current, size) {
            this.params.page = current
            this.params.limit = size
            this.pagination.pageSize = size
            this.pagination.current = current
            this.params.streetCode = this.regionCode
            this.queryCenterCompareResult(this.params)
        },
        changeCeter(codes, time){
            this.params.streetCode = this.regionCode
            this.params.centerCodes = codes
            this.params.startTime = time[0]
            this.params.endTime = time[1]
            this.queryCenterCompareResult(this.params)
        }
    },
    computed: mapState({
        regionCode: state => state.user.module.extendsCode
    })
}
</script>