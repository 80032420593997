<template>
  <div>
    <a-input-search style="margin-bottom: 8px" placeholder="查询小区"   @change="onChange"/>
    <a-tree
      :expanded-keys="expandedKeys"
      :default-expanded-keys="defaultExpandedKeys"
      :tree-data="treeData"
      @expand="onExpand"
      @check="onCheck"
      :replace-fields="replaceFields"
      checkable
      autoExpandParent
      style="height: 600px;overflow: auto;"
    >
      <template slot="title" slot-scope="{ nodeName }">
        <span v-if="nodeName.indexOf(searchValue) > -1">
          {{ nodeName.substr(0, nodeName.indexOf(searchValue)) }}
          <span style="color: #f50">{{ searchValue }}</span>
          {{ nodeName.substr(nodeName.indexOf(searchValue) + searchValue.length) }}
        </span>
        <span v-else>{{ nodeName }}</span>
      </template>
    </a-tree>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { queryCenterTreeApi } from '@/api/compare'
const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.nodeCode === key)) {
        parentKey = node.nodeCode;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};
export default {
  data() {
    return {
      expandedKeys: [],
      expandedNode: {},
      searchValue: '',
      autoExpandParent: true,
      treeData: [],
      replaceFields: {
        children: 'children',
        title: 'nodeName',
        key: 'nodeCode'
      },
      defaultExpandedKeys: [],
      dataList: [],
      checkedNodes: []
    };
  },
  methods: {
    queryCenterTree() {
      queryCenterTreeApi({}, this.$route.query.moduleCode).then(res => {
        this.defaultExpandedKeys = [this.regionCode]
        this.expandedKeys = [this.regionCode]
        res.result.forEach(item => {
          item.scopedSlots = { title: 'title'}
        })
        this.treeData = [
          {
            nodeCode: this.regionCode,
            nodeName: this.regionName,
            nodeLevel: 'area',
            scopedSlots: { title: 'title' },
            children: res.result
          }
        ]
      })
    },
    onChange(e) {
      this.dataList = [];
      this.generateList(this.treeData);
      const value = e.target.value;
      this.searchValue = value
      const expandedKeys = this.dataList
        .map(item => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    generateList(tree){
      let data = tree
      for (let i = 0; i < data.length; i++) {
        const node = data[i]
        const key = node.nodeCode;
        this.dataList.push({ key, title: node.nodeName });
        if (node.children.length > 0) {
          this.generateList(node.children);
        }
      }
    },
    onExpand(expandedKeys, node) {
      this.expandedKeys = expandedKeys;
      this.expandedNode = node.node
      this.autoExpandParent = true;
    },
    onCheck(checkedKeys, checked) {
      this.checkedKeys = checkedKeys;
      this.checkedNodes = checked.checkedNodes
      this.$emit('changeCheckedCenterList', checked.checkedNodes)
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    changeCheckedKeys(data) {
      this.checkedKeys.forEach((item, index) => {
        if(item == data) {
          this.checkedKeys.splice(index, 1)
        }
      })
      this.checkedNodes.forEach((item, index) => {
        if(item.data.key == data) {
          this.checkedNodes.splice(index, 1)
        }
      })
      this.$emit('changeCheckedCenterList', this.checkedNodes)
    }
  },
  computed: mapState({
    regionName: state => state.user.module.regionName,
    regionCode: state => state.user.module.extendsCode
  })
};
</script>