import service from './network';


/**
 *小区对比
 * @param {} data
 * @returns
 */
export function queryCenterTreeApi(data, code) {
    return service.request({
        url: '/street/contrast/center/tree',
        method: 'get',
        headers: {'moduleToken': code}
    })
}

/**
 *小区对比-对比结果
 * @param {} data
 * @returns
 */
export function queryCenterCompareResultApi(data, code) {
    return service.request({
        url: '/street/contrast/query',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

export function UpdateFlowApi(data) {
    return service.request({
        url: '/management/data/flow/analysis/update',
        method: 'put',
        data
    })
}

export function queryFlowInitApi(data) {
    return service.request({
        url: '/management/data/flow/analysis/init/' + data,
        method: 'put',
        data
    })
}

export function queryFlowAnalysisApi(data) {
    return service.request({
        url: '/management/data/flow/analysis/query',
        method: 'post',
        data
    })
}